.inner-container{
    gap: 1.5rem;
    background-color: rgb(27, 27, 158);
    padding: 2rem;
    border-radius: 20px;
    border: 6px solid rgb(148, 148, 229);
    text-align: center;
}

.inner-container .primaryText{
    color: aliceblue;
    font-weight: 600;
}
.inner-container .secondaryText{
    color:rgb(174, 172, 172);
}
.inner-container .button{
    border: 2px solid white;
    box-shadow: var(--shadow);
    border-radius: 10px;
}